/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Store product request.
 * @export
 * @interface StoreProductRequest
 */
export interface StoreProductRequest {
  /**
   *
   * @type {number}
   * @memberof StoreProductRequest
   */
  restaurantId: number;
  /**
   * Must be unique within the restaurant.
   * @type {string}
   * @memberof StoreProductRequest
   */
  slug?: string | null;
  /**
   *
   * @type {string}
   * @memberof StoreProductRequest
   */
  badge?: string | null;
  /**
   *
   * @type {string}
   * @memberof StoreProductRequest
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof StoreProductRequest
   */
  description?: string | null;
  /**
   *
   * @type {number}
   * @memberof StoreProductRequest
   */
  price: number;
  /**
   *
   * @type {number}
   * @memberof StoreProductRequest
   */
  weight?: number;
  /**
   *
   * @type {string}
   * @memberof StoreProductRequest
   */
  weightUnit?: StoreProductRequestWeightUnitEnum;
  /**
   *
   * @type {boolean}
   * @memberof StoreProductRequest
   */
  archived?: boolean;
  /**
   *
   * @type {number}
   * @memberof StoreProductRequest
   */
  popularity?: number;
  /**
   *
   * @type {object}
   * @memberof StoreProductRequest
   */
  metadata?: object | null;
}

/**
 * @export
 */
export const StoreProductRequestWeightUnitEnum = {
  G: "g",
  Kg: "kg",
  Ml: "ml",
  L: "l",
  Cm: "cm",
  Pc: "pc",
} as const;
export type StoreProductRequestWeightUnitEnum =
  (typeof StoreProductRequestWeightUnitEnum)[keyof typeof StoreProductRequestWeightUnitEnum];

/**
 * Check if a given object implements the StoreProductRequest interface.
 */
export function instanceOfStoreProductRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "restaurantId" in value;
  isInstance = isInstance && "title" in value;
  isInstance = isInstance && "price" in value;

  return isInstance;
}

export function StoreProductRequestFromJSON(json: any): StoreProductRequest {
  return StoreProductRequestFromJSONTyped(json, false);
}

export function StoreProductRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): StoreProductRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    restaurantId: json["restaurant_id"],
    slug: !exists(json, "slug") ? undefined : json["slug"],
    badge: !exists(json, "badge") ? undefined : json["badge"],
    title: json["title"],
    description: !exists(json, "description") ? undefined : json["description"],
    price: json["price"],
    weight: !exists(json, "weight") ? undefined : json["weight"],
    weightUnit: !exists(json, "weight_unit") ? undefined : json["weight_unit"],
    archived: !exists(json, "archived") ? undefined : json["archived"],
    popularity: !exists(json, "popularity") ? undefined : json["popularity"],
    metadata: !exists(json, "metadata") ? undefined : json["metadata"],
  };
}

export function StoreProductRequestToJSON(
  value?: StoreProductRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    restaurant_id: value.restaurantId,
    slug: value.slug,
    badge: value.badge,
    title: value.title,
    description: value.description,
    price: value.price,
    weight: value.weight,
    weight_unit: value.weightUnit,
    archived: value.archived,
    popularity: value.popularity,
    metadata: value.metadata,
  };
}
