/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { ScheduleForUpdateRestaurantRequest } from "./ScheduleForUpdateRestaurantRequest";
import {
  ScheduleForUpdateRestaurantRequestFromJSON,
  ScheduleForUpdateRestaurantRequestFromJSONTyped,
  ScheduleForUpdateRestaurantRequestToJSON,
} from "./ScheduleForUpdateRestaurantRequest";

/**
 * Request body for updating restaurant details
 * @export
 * @interface UpdateRestaurantRequest
 */
export interface UpdateRestaurantRequest {
  /**
   * The name of the restaurant.
   * @type {string}
   * @memberof UpdateRestaurantRequest
   */
  name?: string;
  /**
   * The country where the restaurant is located.
   * @type {string}
   * @memberof UpdateRestaurantRequest
   */
  country?: string | null;
  /**
   * The city where the restaurant is located.
   * @type {string}
   * @memberof UpdateRestaurantRequest
   */
  city?: string | null;
  /**
   * Additional information about the restaurant's location.
   * @type {string}
   * @memberof UpdateRestaurantRequest
   */
  place?: string | null;
  /**
   * The contact phone number of the restaurant.
   * @type {string}
   * @memberof UpdateRestaurantRequest
   */
  phone?: string | null;
  /**
   *
   * @type {Array<ScheduleForUpdateRestaurantRequest>}
   * @memberof UpdateRestaurantRequest
   */
  schedules?: Array<ScheduleForUpdateRestaurantRequest>;
}

/**
 * Check if a given object implements the UpdateRestaurantRequest interface.
 */
export function instanceOfUpdateRestaurantRequest(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function UpdateRestaurantRequestFromJSON(
  json: any
): UpdateRestaurantRequest {
  return UpdateRestaurantRequestFromJSONTyped(json, false);
}

export function UpdateRestaurantRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateRestaurantRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, "name") ? undefined : json["name"],
    country: !exists(json, "country") ? undefined : json["country"],
    city: !exists(json, "city") ? undefined : json["city"],
    place: !exists(json, "place") ? undefined : json["place"],
    phone: !exists(json, "phone") ? undefined : json["phone"],
    schedules: !exists(json, "schedules")
      ? undefined
      : (json["schedules"] as Array<any>).map(
          ScheduleForUpdateRestaurantRequestFromJSON
        ),
  };
}

export function UpdateRestaurantRequestToJSON(
  value?: UpdateRestaurantRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    country: value.country,
    city: value.city,
    place: value.place,
    phone: value.phone,
    schedules:
      value.schedules === undefined
        ? undefined
        : (value.schedules as Array<any>).map(
            ScheduleForUpdateRestaurantRequestToJSON
          ),
  };
}
