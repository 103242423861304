/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { AttachingComment } from "./AttachingComment";
import {
  AttachingCommentFromJSON,
  AttachingCommentFromJSONTyped,
  AttachingCommentToJSON,
} from "./AttachingComment";
import type { AttachingDiscount } from "./AttachingDiscount";
import {
  AttachingDiscountFromJSON,
  AttachingDiscountFromJSONTyped,
  AttachingDiscountToJSON,
} from "./AttachingDiscount";
import type { StoreOrderRequestProductField } from "./StoreOrderRequestProductField";
import {
  StoreOrderRequestProductFieldFromJSON,
  StoreOrderRequestProductFieldFromJSONTyped,
  StoreOrderRequestProductFieldToJSON,
} from "./StoreOrderRequestProductField";
import type { StoreOrderRequestServiceField } from "./StoreOrderRequestServiceField";
import {
  StoreOrderRequestServiceFieldFromJSON,
  StoreOrderRequestServiceFieldFromJSONTyped,
  StoreOrderRequestServiceFieldToJSON,
} from "./StoreOrderRequestServiceField";
import type { StoreOrderRequestSpaceField } from "./StoreOrderRequestSpaceField";
import {
  StoreOrderRequestSpaceFieldFromJSON,
  StoreOrderRequestSpaceFieldFromJSONTyped,
  StoreOrderRequestSpaceFieldToJSON,
} from "./StoreOrderRequestSpaceField";
import type { StoreOrderRequestTicketField } from "./StoreOrderRequestTicketField";
import {
  StoreOrderRequestTicketFieldFromJSON,
  StoreOrderRequestTicketFieldFromJSONTyped,
  StoreOrderRequestTicketFieldToJSON,
} from "./StoreOrderRequestTicketField";

/**
 * Store order request
 * @export
 * @interface StoreOrderRequest
 */
export interface StoreOrderRequest {
  /**
   *
   * @type {number}
   * @memberof StoreOrderRequest
   */
  banquetId?: number;
  /**
   *
   * @type {string}
   * @memberof StoreOrderRequest
   */
  slug?: string | null;
  /**
   *
   * @type {string}
   * @memberof StoreOrderRequest
   */
  kind?: StoreOrderRequestKindEnum;
  /**
   *
   * @type {string}
   * @memberof StoreOrderRequest
   */
  state?: StoreOrderRequestStateEnum;
  /**
   *
   * @type {string}
   * @memberof StoreOrderRequest
   */
  recipient?: string | null;
  /**
   *
   * @type {string}
   * @memberof StoreOrderRequest
   */
  phone?: string | null;
  /**
   *
   * @type {string}
   * @memberof StoreOrderRequest
   */
  address?: string | null;
  /**
   *
   * @type {string}
   * @memberof StoreOrderRequest
   */
  deliveryTime?: string | null;
  /**
   *
   * @type {Date}
   * @memberof StoreOrderRequest
   */
  deliveryDate?: Date | null;
  /**
   *
   * @type {Array<StoreOrderRequestSpaceField>}
   * @memberof StoreOrderRequest
   */
  spaces?: Array<StoreOrderRequestSpaceField>;
  /**
   *
   * @type {Array<StoreOrderRequestTicketField>}
   * @memberof StoreOrderRequest
   */
  tickets?: Array<StoreOrderRequestTicketField>;
  /**
   *
   * @type {Array<StoreOrderRequestServiceField>}
   * @memberof StoreOrderRequest
   */
  services?: Array<StoreOrderRequestServiceField>;
  /**
   *
   * @type {Array<StoreOrderRequestProductField>}
   * @memberof StoreOrderRequest
   */
  products?: Array<StoreOrderRequestProductField>;
  /**
   *
   * @type {Array<AttachingComment>}
   * @memberof StoreOrderRequest
   */
  comments?: Array<AttachingComment>;
  /**
   *
   * @type {Array<AttachingDiscount>}
   * @memberof StoreOrderRequest
   */
  discounts?: Array<AttachingDiscount>;
}

/**
 * @export
 */
export const StoreOrderRequestKindEnum = {
  Delivery: "delivery",
  Banquet: "banquet",
} as const;
export type StoreOrderRequestKindEnum =
  (typeof StoreOrderRequestKindEnum)[keyof typeof StoreOrderRequestKindEnum];

/**
 * @export
 */
export const StoreOrderRequestStateEnum = {
  New: "new",
  Confirmed: "confirmed",
  Postponed: "postponed",
  Cancelled: "cancelled",
  Completed: "completed",
} as const;
export type StoreOrderRequestStateEnum =
  (typeof StoreOrderRequestStateEnum)[keyof typeof StoreOrderRequestStateEnum];

/**
 * Check if a given object implements the StoreOrderRequest interface.
 */
export function instanceOfStoreOrderRequest(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function StoreOrderRequestFromJSON(json: any): StoreOrderRequest {
  return StoreOrderRequestFromJSONTyped(json, false);
}

export function StoreOrderRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): StoreOrderRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    banquetId: !exists(json, "banquet_id") ? undefined : json["banquet_id"],
    slug: !exists(json, "slug") ? undefined : json["slug"],
    kind: !exists(json, "kind") ? undefined : json["kind"],
    state: !exists(json, "state") ? undefined : json["state"],
    recipient: !exists(json, "recipient") ? undefined : json["recipient"],
    phone: !exists(json, "phone") ? undefined : json["phone"],
    address: !exists(json, "address") ? undefined : json["address"],
    deliveryTime: !exists(json, "delivery_time")
      ? undefined
      : json["delivery_time"],
    deliveryDate: !exists(json, "delivery_date")
      ? undefined
      : json["delivery_date"] === null
      ? null
      : new Date(json["delivery_date"]),
    spaces: !exists(json, "spaces")
      ? undefined
      : (json["spaces"] as Array<any>).map(StoreOrderRequestSpaceFieldFromJSON),
    tickets: !exists(json, "tickets")
      ? undefined
      : (json["tickets"] as Array<any>).map(
          StoreOrderRequestTicketFieldFromJSON
        ),
    services: !exists(json, "services")
      ? undefined
      : (json["services"] as Array<any>).map(
          StoreOrderRequestServiceFieldFromJSON
        ),
    products: !exists(json, "products")
      ? undefined
      : (json["products"] as Array<any>).map(
          StoreOrderRequestProductFieldFromJSON
        ),
    comments: !exists(json, "comments")
      ? undefined
      : (json["comments"] as Array<any>).map(AttachingCommentFromJSON),
    discounts: !exists(json, "discounts")
      ? undefined
      : (json["discounts"] as Array<any>).map(AttachingDiscountFromJSON),
  };
}

export function StoreOrderRequestToJSON(value?: StoreOrderRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    banquet_id: value.banquetId,
    slug: value.slug,
    kind: value.kind,
    state: value.state,
    recipient: value.recipient,
    phone: value.phone,
    address: value.address,
    delivery_time: value.deliveryTime,
    delivery_date:
      value.deliveryDate === undefined
        ? undefined
        : value.deliveryDate === null
        ? null
        : value.deliveryDate.toISOString().substr(0, 10),
    spaces:
      value.spaces === undefined
        ? undefined
        : (value.spaces as Array<any>).map(StoreOrderRequestSpaceFieldToJSON),
    tickets:
      value.tickets === undefined
        ? undefined
        : (value.tickets as Array<any>).map(StoreOrderRequestTicketFieldToJSON),
    services:
      value.services === undefined
        ? undefined
        : (value.services as Array<any>).map(
            StoreOrderRequestServiceFieldToJSON
          ),
    products:
      value.products === undefined
        ? undefined
        : (value.products as Array<any>).map(
            StoreOrderRequestProductFieldToJSON
          ),
    comments:
      value.comments === undefined
        ? undefined
        : (value.comments as Array<any>).map(AttachingCommentToJSON),
    discounts:
      value.discounts === undefined
        ? undefined
        : (value.discounts as Array<any>).map(AttachingDiscountToJSON),
  };
}
