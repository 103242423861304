/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Detach category from menu response object.
 * @export
 * @interface DetachCategoryFromMenuResponse
 */
export interface DetachCategoryFromMenuResponse {
  /**
   *
   * @type {string}
   * @memberof DetachCategoryFromMenuResponse
   */
  message: string;
}

/**
 * Check if a given object implements the DetachCategoryFromMenuResponse interface.
 */
export function instanceOfDetachCategoryFromMenuResponse(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && "message" in value;

  return isInstance;
}

export function DetachCategoryFromMenuResponseFromJSON(
  json: any
): DetachCategoryFromMenuResponse {
  return DetachCategoryFromMenuResponseFromJSONTyped(json, false);
}

export function DetachCategoryFromMenuResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DetachCategoryFromMenuResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    message: json["message"],
  };
}

export function DetachCategoryFromMenuResponseToJSON(
  value?: DetachCategoryFromMenuResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    message: value.message,
  };
}
