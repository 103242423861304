/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Attach category to menu request.
 * @export
 * @interface AttachCategoryToMenuRequest
 */
export interface AttachCategoryToMenuRequest {
  /**
   *
   * @type {number}
   * @memberof AttachCategoryToMenuRequest
   */
  menuId: number;
  /**
   *
   * @type {number}
   * @memberof AttachCategoryToMenuRequest
   */
  categoryId: number;
}

/**
 * Check if a given object implements the AttachCategoryToMenuRequest interface.
 */
export function instanceOfAttachCategoryToMenuRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "menuId" in value;
  isInstance = isInstance && "categoryId" in value;

  return isInstance;
}

export function AttachCategoryToMenuRequestFromJSON(
  json: any
): AttachCategoryToMenuRequest {
  return AttachCategoryToMenuRequestFromJSONTyped(json, false);
}

export function AttachCategoryToMenuRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AttachCategoryToMenuRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    menuId: json["menu_id"],
    categoryId: json["category_id"],
  };
}

export function AttachCategoryToMenuRequestToJSON(
  value?: AttachCategoryToMenuRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    menu_id: value.menuId,
    category_id: value.categoryId,
  };
}
