/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Comment } from "./Comment";
import {
  CommentFromJSON,
  CommentFromJSONTyped,
  CommentToJSON,
} from "./Comment";
import type { Discount } from "./Discount";
import {
  DiscountFromJSON,
  DiscountFromJSONTyped,
  DiscountToJSON,
} from "./Discount";
import type { OrderTotals } from "./OrderTotals";
import {
  OrderTotalsFromJSON,
  OrderTotalsFromJSONTyped,
  OrderTotalsToJSON,
} from "./OrderTotals";
import type { ProductOrderField } from "./ProductOrderField";
import {
  ProductOrderFieldFromJSON,
  ProductOrderFieldFromJSONTyped,
  ProductOrderFieldToJSON,
} from "./ProductOrderField";
import type { ServiceOrderField } from "./ServiceOrderField";
import {
  ServiceOrderFieldFromJSON,
  ServiceOrderFieldFromJSONTyped,
  ServiceOrderFieldToJSON,
} from "./ServiceOrderField";
import type { SpaceOrderField } from "./SpaceOrderField";
import {
  SpaceOrderFieldFromJSON,
  SpaceOrderFieldFromJSONTyped,
  SpaceOrderFieldToJSON,
} from "./SpaceOrderField";
import type { TicketOrderField } from "./TicketOrderField";
import {
  TicketOrderFieldFromJSON,
  TicketOrderFieldFromJSONTyped,
  TicketOrderFieldToJSON,
} from "./TicketOrderField";

/**
 * Order resource object
 * @export
 * @interface Order
 */
export interface Order {
  /**
   *
   * @type {number}
   * @memberof Order
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof Order
   */
  banquetId: number;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  slug: string | null;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  kind: OrderKindEnum;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  state: OrderStateEnum;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  recipient: string | null;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  phone: string | null;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  address: string | null;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  deliveryTime: string | null;
  /**
   *
   * @type {Date}
   * @memberof Order
   */
  deliveryDate: Date | null;
  /**
   *
   * @type {OrderTotals}
   * @memberof Order
   */
  totals: OrderTotals;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  invoiceUrl?: string | null;
  /**
   *
   * @type {Array<SpaceOrderField>}
   * @memberof Order
   */
  spaces?: Array<SpaceOrderField>;
  /**
   *
   * @type {Array<TicketOrderField>}
   * @memberof Order
   */
  tickets?: Array<TicketOrderField>;
  /**
   *
   * @type {Array<ProductOrderField>}
   * @memberof Order
   */
  products?: Array<ProductOrderField>;
  /**
   *
   * @type {Array<ServiceOrderField>}
   * @memberof Order
   */
  services?: Array<ServiceOrderField>;
  /**
   *
   * @type {Array<Comment>}
   * @memberof Order
   */
  comments?: Array<Comment>;
  /**
   *
   * @type {Array<Discount>}
   * @memberof Order
   */
  discounts?: Array<Discount>;
}

/**
 * @export
 */
export const OrderKindEnum = {
  Delivery: "delivery",
  Banquet: "banquet",
} as const;
export type OrderKindEnum = (typeof OrderKindEnum)[keyof typeof OrderKindEnum];

/**
 * @export
 */
export const OrderStateEnum = {
  New: "new",
  Confirmed: "confirmed",
  Postponed: "postponed",
  Cancelled: "cancelled",
  Completed: "completed",
} as const;
export type OrderStateEnum =
  (typeof OrderStateEnum)[keyof typeof OrderStateEnum];

/**
 * Check if a given object implements the Order interface.
 */
export function instanceOfOrder(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "banquetId" in value;
  isInstance = isInstance && "slug" in value;
  isInstance = isInstance && "type" in value;
  isInstance = isInstance && "kind" in value;
  isInstance = isInstance && "state" in value;
  isInstance = isInstance && "recipient" in value;
  isInstance = isInstance && "phone" in value;
  isInstance = isInstance && "address" in value;
  isInstance = isInstance && "deliveryTime" in value;
  isInstance = isInstance && "deliveryDate" in value;
  isInstance = isInstance && "totals" in value;

  return isInstance;
}

export function OrderFromJSON(json: any): Order {
  return OrderFromJSONTyped(json, false);
}

export function OrderFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Order {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    banquetId: json["banquet_id"],
    slug: json["slug"],
    type: json["type"],
    kind: json["kind"],
    state: json["state"],
    recipient: json["recipient"],
    phone: json["phone"],
    address: json["address"],
    deliveryTime: json["delivery_time"],
    deliveryDate:
      json["delivery_date"] === null ? null : new Date(json["delivery_date"]),
    totals: OrderTotalsFromJSON(json["totals"]),
    invoiceUrl: !exists(json, "invoice_url") ? undefined : json["invoice_url"],
    spaces: !exists(json, "spaces")
      ? undefined
      : (json["spaces"] as Array<any>).map(SpaceOrderFieldFromJSON),
    tickets: !exists(json, "tickets")
      ? undefined
      : (json["tickets"] as Array<any>).map(TicketOrderFieldFromJSON),
    products: !exists(json, "products")
      ? undefined
      : (json["products"] as Array<any>).map(ProductOrderFieldFromJSON),
    services: !exists(json, "services")
      ? undefined
      : (json["services"] as Array<any>).map(ServiceOrderFieldFromJSON),
    comments: !exists(json, "comments")
      ? undefined
      : (json["comments"] as Array<any>).map(CommentFromJSON),
    discounts: !exists(json, "discounts")
      ? undefined
      : (json["discounts"] as Array<any>).map(DiscountFromJSON),
  };
}

export function OrderToJSON(value?: Order | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    banquet_id: value.banquetId,
    slug: value.slug,
    type: value.type,
    kind: value.kind,
    state: value.state,
    recipient: value.recipient,
    phone: value.phone,
    address: value.address,
    delivery_time: value.deliveryTime,
    delivery_date:
      value.deliveryDate === null
        ? null
        : value.deliveryDate.toISOString().substr(0, 10),
    totals: OrderTotalsToJSON(value.totals),
    invoice_url: value.invoiceUrl,
    spaces:
      value.spaces === undefined
        ? undefined
        : (value.spaces as Array<any>).map(SpaceOrderFieldToJSON),
    tickets:
      value.tickets === undefined
        ? undefined
        : (value.tickets as Array<any>).map(TicketOrderFieldToJSON),
    products:
      value.products === undefined
        ? undefined
        : (value.products as Array<any>).map(ProductOrderFieldToJSON),
    services:
      value.services === undefined
        ? undefined
        : (value.services as Array<any>).map(ServiceOrderFieldToJSON),
    comments:
      value.comments === undefined
        ? undefined
        : (value.comments as Array<any>).map(CommentToJSON),
    discounts:
      value.discounts === undefined
        ? undefined
        : (value.discounts as Array<any>).map(DiscountToJSON),
  };
}
