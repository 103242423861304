/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Request body for updating restaurant's schedules details
 * @export
 * @interface ScheduleForUpdateRestaurantRequest
 */
export interface ScheduleForUpdateRestaurantRequest {
  /**
   *
   * @type {string}
   * @memberof ScheduleForUpdateRestaurantRequest
   */
  weekday: ScheduleForUpdateRestaurantRequestWeekdayEnum;
  /**
   * Start hour of the day [0 ; 23].
   * @type {number}
   * @memberof ScheduleForUpdateRestaurantRequest
   */
  begHour: number;
  /**
   * End hour of the day [0 ; 23]. If less then beg_hour it,
   *      *   then it meens that it's a cross day schedule
   * @type {number}
   * @memberof ScheduleForUpdateRestaurantRequest
   */
  endHour: number;
  /**
   * Start minute of the day [0 ; 59].
   * @type {number}
   * @memberof ScheduleForUpdateRestaurantRequest
   */
  begMinute: number;
  /**
   * End minute of the day [0 ; 59].
   * @type {number}
   * @memberof ScheduleForUpdateRestaurantRequest
   */
  endMinute: number;
  /**
   *
   * @type {boolean}
   * @memberof ScheduleForUpdateRestaurantRequest
   */
  archived: boolean;
}

/**
 * @export
 */
export const ScheduleForUpdateRestaurantRequestWeekdayEnum = {
  Monday: "monday",
  Tuesday: "tuesday",
  Wednesday: "wednesday",
  Thursday: "thursday",
  Friday: "friday",
  Saturday: "saturday",
  Sunday: "sunday",
} as const;
export type ScheduleForUpdateRestaurantRequestWeekdayEnum =
  (typeof ScheduleForUpdateRestaurantRequestWeekdayEnum)[keyof typeof ScheduleForUpdateRestaurantRequestWeekdayEnum];

/**
 * Check if a given object implements the ScheduleForUpdateRestaurantRequest interface.
 */
export function instanceOfScheduleForUpdateRestaurantRequest(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && "weekday" in value;
  isInstance = isInstance && "begHour" in value;
  isInstance = isInstance && "endHour" in value;
  isInstance = isInstance && "begMinute" in value;
  isInstance = isInstance && "endMinute" in value;
  isInstance = isInstance && "archived" in value;

  return isInstance;
}

export function ScheduleForUpdateRestaurantRequestFromJSON(
  json: any
): ScheduleForUpdateRestaurantRequest {
  return ScheduleForUpdateRestaurantRequestFromJSONTyped(json, false);
}

export function ScheduleForUpdateRestaurantRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ScheduleForUpdateRestaurantRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    weekday: json["weekday"],
    begHour: json["beg_hour"],
    endHour: json["end_hour"],
    begMinute: json["beg_minute"],
    endMinute: json["end_minute"],
    archived: json["archived"],
  };
}

export function ScheduleForUpdateRestaurantRequestToJSON(
  value?: ScheduleForUpdateRestaurantRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    weekday: value.weekday,
    beg_hour: value.begHour,
    end_hour: value.endHour,
    beg_minute: value.begMinute,
    end_minute: value.endMinute,
    archived: value.archived,
  };
}
