/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  ArchivedParameter,
  AttachCategoryToMenuRequest,
  AttachCategoryToMenuResponse,
  AttachProductToMenuRequest,
  AttachProductToMenuResponse,
  DetachCategoryFromMenuRequest,
  DetachCategoryFromMenuResponse,
  DetachProductFromMenuRequest,
  DetachProductFromMenuResponse,
  IndexMenuResponse,
  ShowMenuResponse,
  UnauthenticatedResponse,
} from "../models";
import {
  ArchivedParameterFromJSON,
  ArchivedParameterToJSON,
  AttachCategoryToMenuRequestFromJSON,
  AttachCategoryToMenuRequestToJSON,
  AttachCategoryToMenuResponseFromJSON,
  AttachCategoryToMenuResponseToJSON,
  AttachProductToMenuRequestFromJSON,
  AttachProductToMenuRequestToJSON,
  AttachProductToMenuResponseFromJSON,
  AttachProductToMenuResponseToJSON,
  DetachCategoryFromMenuRequestFromJSON,
  DetachCategoryFromMenuRequestToJSON,
  DetachCategoryFromMenuResponseFromJSON,
  DetachCategoryFromMenuResponseToJSON,
  DetachProductFromMenuRequestFromJSON,
  DetachProductFromMenuRequestToJSON,
  DetachProductFromMenuResponseFromJSON,
  DetachProductFromMenuResponseToJSON,
  IndexMenuResponseFromJSON,
  IndexMenuResponseToJSON,
  ShowMenuResponseFromJSON,
  ShowMenuResponseToJSON,
  UnauthenticatedResponseFromJSON,
  UnauthenticatedResponseToJSON,
} from "../models";

export interface AttachCategoryToMenuOperationRequest {
  attachCategoryToMenuRequest: AttachCategoryToMenuRequest;
}

export interface AttachProductToMenuOperationRequest {
  attachProductToMenuRequest: AttachProductToMenuRequest;
}

export interface DetachCategoryFromMenuOperationRequest {
  detachCategoryFromMenuRequest: DetachCategoryFromMenuRequest;
}

export interface DetachProductFromMenuOperationRequest {
  detachProductFromMenuRequest: DetachProductFromMenuRequest;
}

export interface IndexMenusRequest {
  include?: string;
  pageSize?: number;
  pageNumber?: number;
  sort?: string;
  filterRestaurants?: string;
  archived?: ArchivedParameter;
}

export interface ShowMenuRequest {
  id: number;
  include?: string;
}

/**
 *
 */
export class MenusApi extends runtime.BaseAPI {
  /**
   * Attach category to menu.
   */
  async attachCategoryToMenuRaw(
    requestParameters: AttachCategoryToMenuOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AttachCategoryToMenuResponse>> {
    if (
      requestParameters.attachCategoryToMenuRequest === null ||
      requestParameters.attachCategoryToMenuRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "attachCategoryToMenuRequest",
        "Required parameter requestParameters.attachCategoryToMenuRequest was null or undefined when calling attachCategoryToMenu."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/menus/attach-category`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: AttachCategoryToMenuRequestToJSON(
          requestParameters.attachCategoryToMenuRequest
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AttachCategoryToMenuResponseFromJSON(jsonValue)
    );
  }

  /**
   * Attach category to menu.
   */
  async attachCategoryToMenu(
    requestParameters: AttachCategoryToMenuOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AttachCategoryToMenuResponse> {
    const response = await this.attachCategoryToMenuRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Attach product to menu.
   */
  async attachProductToMenuRaw(
    requestParameters: AttachProductToMenuOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AttachProductToMenuResponse>> {
    if (
      requestParameters.attachProductToMenuRequest === null ||
      requestParameters.attachProductToMenuRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "attachProductToMenuRequest",
        "Required parameter requestParameters.attachProductToMenuRequest was null or undefined when calling attachProductToMenu."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/menus/attach-product`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: AttachProductToMenuRequestToJSON(
          requestParameters.attachProductToMenuRequest
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AttachProductToMenuResponseFromJSON(jsonValue)
    );
  }

  /**
   * Attach product to menu.
   */
  async attachProductToMenu(
    requestParameters: AttachProductToMenuOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AttachProductToMenuResponse> {
    const response = await this.attachProductToMenuRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Detach category from menu.
   */
  async detachCategoryFromMenuRaw(
    requestParameters: DetachCategoryFromMenuOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<DetachCategoryFromMenuResponse>> {
    if (
      requestParameters.detachCategoryFromMenuRequest === null ||
      requestParameters.detachCategoryFromMenuRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "detachCategoryFromMenuRequest",
        "Required parameter requestParameters.detachCategoryFromMenuRequest was null or undefined when calling detachCategoryFromMenu."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/menus/detach-category`,
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
        body: DetachCategoryFromMenuRequestToJSON(
          requestParameters.detachCategoryFromMenuRequest
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      DetachCategoryFromMenuResponseFromJSON(jsonValue)
    );
  }

  /**
   * Detach category from menu.
   */
  async detachCategoryFromMenu(
    requestParameters: DetachCategoryFromMenuOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<DetachCategoryFromMenuResponse> {
    const response = await this.detachCategoryFromMenuRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Detach product from menu.
   */
  async detachProductFromMenuRaw(
    requestParameters: DetachProductFromMenuOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<DetachProductFromMenuResponse>> {
    if (
      requestParameters.detachProductFromMenuRequest === null ||
      requestParameters.detachProductFromMenuRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "detachProductFromMenuRequest",
        "Required parameter requestParameters.detachProductFromMenuRequest was null or undefined when calling detachProductFromMenu."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/menus/detach-product`,
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
        body: DetachProductFromMenuRequestToJSON(
          requestParameters.detachProductFromMenuRequest
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      DetachProductFromMenuResponseFromJSON(jsonValue)
    );
  }

  /**
   * Detach product from menu.
   */
  async detachProductFromMenu(
    requestParameters: DetachProductFromMenuOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<DetachProductFromMenuResponse> {
    const response = await this.detachProductFromMenuRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Index menus.
   */
  async indexMenusRaw(
    requestParameters: IndexMenusRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<IndexMenuResponse>> {
    const queryParameters: any = {};

    if (requestParameters.include !== undefined) {
      queryParameters["include"] = requestParameters.include;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["page[size]"] = requestParameters.pageSize;
    }

    if (requestParameters.pageNumber !== undefined) {
      queryParameters["page[number]"] = requestParameters.pageNumber;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.filterRestaurants !== undefined) {
      queryParameters["filter[restaurants]"] =
        requestParameters.filterRestaurants;
    }

    if (requestParameters.archived !== undefined) {
      queryParameters["archived"] = requestParameters.archived;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/menus`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      IndexMenuResponseFromJSON(jsonValue)
    );
  }

  /**
   * Index menus.
   */
  async indexMenus(
    requestParameters: IndexMenusRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<IndexMenuResponse> {
    const response = await this.indexMenusRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Show menus by id.
   */
  async showMenuRaw(
    requestParameters: ShowMenuRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ShowMenuResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling showMenu."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.include !== undefined) {
      queryParameters["include"] = requestParameters.include;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/menus/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ShowMenuResponseFromJSON(jsonValue)
    );
  }

  /**
   * Show menus by id.
   */
  async showMenu(
    requestParameters: ShowMenuRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ShowMenuResponse> {
    const response = await this.showMenuRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
