/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Product } from "./Product";
import {
  ProductFromJSON,
  ProductFromJSONTyped,
  ProductToJSON,
} from "./Product";

/**
 * Update product response object.
 * @export
 * @interface UpdateProductResponse
 */
export interface UpdateProductResponse {
  /**
   *
   * @type {Product}
   * @memberof UpdateProductResponse
   */
  data: Product;
  /**
   *
   * @type {string}
   * @memberof UpdateProductResponse
   */
  message: string;
}

/**
 * Check if a given object implements the UpdateProductResponse interface.
 */
export function instanceOfUpdateProductResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "data" in value;
  isInstance = isInstance && "message" in value;

  return isInstance;
}

export function UpdateProductResponseFromJSON(
  json: any
): UpdateProductResponse {
  return UpdateProductResponseFromJSONTyped(json, false);
}

export function UpdateProductResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateProductResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: ProductFromJSON(json["data"]),
    message: json["message"],
  };
}

export function UpdateProductResponseToJSON(
  value?: UpdateProductResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: ProductToJSON(value.data),
    message: value.message,
  };
}
