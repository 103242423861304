/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Detach product from menu request.
 * @export
 * @interface DetachProductFromMenuRequest
 */
export interface DetachProductFromMenuRequest {
  /**
   *
   * @type {number}
   * @memberof DetachProductFromMenuRequest
   */
  menuId: number;
  /**
   *
   * @type {number}
   * @memberof DetachProductFromMenuRequest
   */
  productId: number;
}

/**
 * Check if a given object implements the DetachProductFromMenuRequest interface.
 */
export function instanceOfDetachProductFromMenuRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "menuId" in value;
  isInstance = isInstance && "productId" in value;

  return isInstance;
}

export function DetachProductFromMenuRequestFromJSON(
  json: any
): DetachProductFromMenuRequest {
  return DetachProductFromMenuRequestFromJSONTyped(json, false);
}

export function DetachProductFromMenuRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DetachProductFromMenuRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    menuId: json["menu_id"],
    productId: json["product_id"],
  };
}

export function DetachProductFromMenuRequestToJSON(
  value?: DetachProductFromMenuRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    menu_id: value.menuId,
    product_id: value.productId,
  };
}
