<template>
  <div class="flex-col justify-start items-start">
    <div class="date">
      <BaseIcon :width="48" :height="48" title="date" :color="errors ? 'var(--red)' : 'currentColor'"
                class="grow-0 rounded bg-base-200 p-2.5 hover:bg-base-300">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.6663 9.9043H2.84229C2.42829 9.9043 2.09229 9.5683 2.09229 9.1543C2.09229 8.7403 2.42829 8.4043 2.84229 8.4043H20.6663C21.0803 8.4043 21.4163 8.7403 21.4163 9.1543C21.4163 9.5683 21.0803 9.9043 20.6663 9.9043Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2013 13.8096C15.7873 13.8096 15.4473 13.4736 15.4473 13.0596C15.4473 12.6456 15.7783 12.3096 16.1923 12.3096H16.2013C16.6153 12.3096 16.9513 12.6456 16.9513 13.0596C16.9513 13.4736 16.6153 13.8096 16.2013 13.8096Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7638 13.8096C11.3498 13.8096 11.0098 13.4736 11.0098 13.0596C11.0098 12.6456 11.3408 12.3096 11.7548 12.3096H11.7638C12.1778 12.3096 12.5138 12.6456 12.5138 13.0596C12.5138 13.4736 12.1778 13.8096 11.7638 13.8096Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.31701 13.8096C6.90301 13.8096 6.56201 13.4736 6.56201 13.0596C6.56201 12.6456 6.89401 12.3096 7.30801 12.3096H7.31701C7.73101 12.3096 8.06701 12.6456 8.06701 13.0596C8.06701 13.4736 7.73101 13.8096 7.31701 13.8096Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2013 17.6963C15.7873 17.6963 15.4473 17.3603 15.4473 16.9463C15.4473 16.5323 15.7783 16.1963 16.1923 16.1963H16.2013C16.6153 16.1963 16.9513 16.5323 16.9513 16.9463C16.9513 17.3603 16.6153 17.6963 16.2013 17.6963Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7638 17.6963C11.3498 17.6963 11.0098 17.3603 11.0098 16.9463C11.0098 16.5323 11.3408 16.1963 11.7548 16.1963H11.7638C12.1778 16.1963 12.5138 16.5323 12.5138 16.9463C12.5138 17.3603 12.1778 17.6963 11.7638 17.6963Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.31701 17.6963C6.90301 17.6963 6.56201 17.3603 6.56201 16.9463C6.56201 16.5323 6.89401 16.1963 7.30801 16.1963H7.31701C7.73101 16.1963 8.06701 16.5323 8.06701 16.9463C8.06701 17.3603 7.73101 17.6963 7.31701 17.6963Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7935 5.791C15.3795 5.791 15.0435 5.455 15.0435 5.041V1.75C15.0435 1.336 15.3795 1 15.7935 1C16.2075 1 16.5435 1.336 16.5435 1.75V5.041C16.5435 5.455 16.2075 5.791 15.7935 5.791Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.71533 5.791C7.30133 5.791 6.96533 5.455 6.96533 5.041V1.75C6.96533 1.336 7.30133 1 7.71533 1C8.12933 1 8.46533 1.336 8.46533 1.75V5.041C8.46533 5.455 8.12933 5.791 7.71533 5.791Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.521 4.0791C4.928 4.0791 3.5 5.4621 3.5 7.9731V17.0221C3.5 19.5881 4.928 21.0001 7.521 21.0001H15.979C18.572 21.0001 20 19.6141 20 17.0981V7.9731C20.004 6.7381 19.672 5.7781 19.013 5.1181C18.335 4.4381 17.29 4.0791 15.988 4.0791H7.521ZM15.979 22.5001H7.521C4.116 22.5001 2 20.4011 2 17.0221V7.9731C2 4.6451 4.116 2.5791 7.521 2.5791H15.988C17.697 2.5791 19.11 3.0911 20.075 4.0581C21.012 4.9991 21.505 6.3521 21.5 7.9751V17.0981C21.5 20.4301 19.384 22.5001 15.979 22.5001Z"/>
      </BaseIcon>

      <div class="date-info" >
        <span class="text-md font-semibold">{{ val }}</span>
        <!--      <span class="text-md font-semibold">{{ dayOfWeek }}</span>-->
      </div>
    </div>

    <label class="label flex-col items-start" v-if="errors">
      <span class="label-text-alt text-error text-sm" v-for="error in errors" :key="error">
        {{ error }}
      </span>
    </label>
  </div>
</template>

<script>
import { dateFormatted } from "@/helpers";
import { defineComponent } from "vue";
import BaseIcon from '@/components/icons/BaseIcon.vue';

export default defineComponent({
  // eslint-disable-next-line
  name: "Date",
  components: {
    BaseIcon,
  },
  props: {
    date: Date,
    readonly: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: null,
    }
  },
  computed: {
    val() {
      if (!this.date) {
        return this.$t('banquet.calendar.placeholder');
      }

      return dateFormatted(this.date);
    },
    dayOfWeek() {
      const daysOfWeek = [
        this.$t('weekday.sunday'),
        this.$t('weekday.monday'),
        this.$t('weekday.tuesday'),
        this.$t('weekday.wednesday'),
        this.$t('weekday.thursday'),
        this.$t('weekday.friday'),
        this.$t('weekday.saturday'),
      ];

      const dayOfWeek = new Date(this.date).getDay();

      return isNaN(dayOfWeek)
          ? null : daysOfWeek[dayOfWeek];
    }
  }
});
</script>


<style scoped>
.date {
  @apply flex flex-wrap gap-2 justify-start items-center;
}

.date-info {
  @apply flex justify-center items-center flex-col;
}
</style>