export default {
  "theme": {
    "lofi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light"])},
    "dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark"])}
  },
  "weekday": {
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])},
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])}
  },
  "month": {
    "january": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
    "february": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
    "march": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
    "april": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
    "may": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
    "june": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
    "july": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
    "august": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
    "september": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
    "october": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
    "november": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
    "december": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])}
  },
  "unit": {
    "g": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["g"])},
    "kg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kg"])},
    "l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["l"])},
    "ml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ml"])},
    "cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cm"])},
    "pc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pc"])}
  },
  "schedule": {
    "working_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working schedule"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
    "hour_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["h"])},
    "minute_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["m"])},
    "T_until_closing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " until closing"])},
    "T_before_opening": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " before opening"])}
  },
  "error": {
    "back_to_main": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to previous"])}
    },
    "reload_page": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload page"])}
    },
    "something_went_wrong": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops, something went wrong..."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error while loading data from server."])}
    },
    "Invalid credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid credentials."])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome back!"])},
    "register_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account?"])},
    "login_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])}
  },
  "register": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First time here?"])},
    "login_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account?"])},
    "register_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Confirmation"])}
  },
  "preview": {
    "navbar": {
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
      "theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theme"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
      "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
      "new_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New order"])},
      "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])},
      "street_and_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street, address"])}
    },
    "restaurant": {
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading restaurant"])},
      "loading_menus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading menus"])},
      "menus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menus"])},
      "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])}
    },
    "restaurants": {
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading restaurants"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select restaurant"])},
      "select_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])}
    },
    "menu": {
      "switcher": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menus"])}
      },
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading menus"])},
      "loading_spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading spaces"])},
      "loading_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading items"])},
      "loading_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading services"])},
      "unfortunately_list_is_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately the list is empty"])}
    },
    "space": {
      "floor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Floor"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])}
    },
    "item": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])}
    },
    "order": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No items added"])},
      "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for"])},
      "item_singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["item"])},
      "item_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["items"])},
      "item_plural2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["items"])},
      "item_plural5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["items"])},
      "space_singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["booking"])},
      "space_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bookings"])},
      "space_plural2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bookings"])},
      "space_plural5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bookings"])},
      "ticket_singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ticket"])},
      "ticket_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tickets"])},
      "ticket_plural2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tickets"])},
      "ticket_plural5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tickets"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading order"])},
      "loading_spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading spaces"])},
      "loading_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading products"])},
      "loading_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading serivces"])},
      "new_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New order"])},
      "store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store order"])},
      "was_successfully_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order was successfully saved"])},
      "an_error_occurred_while_saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while saving order"])},
      "errors": {
        "serve_at": {
          "invalid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Invalid serve at time: ", _interpolate(_named("time")), ". Examples of correct format: 8:30, 11:40, 16:05"])}
        }
      }
    },
    "tip": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip"])},
      "leave_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave tip"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
      "for": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For waiter, service, food, cleanness"])},
        "waiter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For waiter"])},
        "waiter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip a waiter"])},
        "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For service"])},
        "service_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip for the service that you were given"])},
        "food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For food"])},
        "food_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip the cooks"])},
        "cleanness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For cleanness"])},
        "cleanness_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip the stuff members, who keep everything clean"])}
      },
      "showing_C_of_T": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Showing ", _interpolate(_named("count")), " of ", _interpolate(_named("total")), " waiters"])},
      "showing_all_C": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Showing all waiters (", _interpolate(_named("count")), ")"])},
      "load_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load more..."])},
      "errors": {
        "amount": {
          "min": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minimum amount: ", _interpolate(_named("amount"))])}
        }
      },
      "unfortunately_list_is_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately the list is empty"])}
    }
  },
  "product": {
    "serving_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serving time"])}
  },
  "reviews": {
    "unfortunately_list_is_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately the list is empty"])},
    "click_to_leave_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to leave review"])},
    "review_restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review restaurant"])},
    "store_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store review"])},
    "your_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your review"])},
    "your_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name"])},
    "select_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select score"])},
    "load_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load more..."])},
    "showing_C_of_T": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Showing ", _interpolate(_named("count")), " of ", _interpolate(_named("total")), " reviews"])},
    "showing_all_C": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Showing all reviews (", _interpolate(_named("count")), ")"])},
    "errors": {
      "ip": {
        "failed_to_resolve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to resolve your IP-address, so we won't be able to store the review."])}
      },
      "name": {
        "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your name"])},
        "min2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name must be at least 2 characters"])}
      },
      "description": {
        "min5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review must be at least 5 characters"])}
      }
    }
  },
  "banquet": {
    "banquet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banquet"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "price_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "actual_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actual total"])},
    "advance_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advance amount"])},
    "advance_amount_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advance amount payment method"])},
    "marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marks"])},
    "birthday_club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday club"])},
    "photographer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photographer"])},
    "adults_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adults amount"])},
    "adult_ticket_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adult ticket price"])},
    "children_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Children amount"])},
    "child_ticket_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Children ticket price"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create banquet"])},
    "store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store banquet"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading banquet"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
    "create_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create comment"])},
    "more_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More details"])},
    "was_successfully_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banquet was successfully saved"])},
    "an_error_occurred_while_saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while saving banquet"])},
    "more": {
      "bill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bill"])}
    },
    "payment_method": {
      "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card"])},
      "cash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash"])}
    },
    "bill": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bill"])},
      "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "restaurant_and_client_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant and client info"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
      "menus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menus"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
      "sections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sections"])},
      "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets"])},
      "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
      "spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaces"])}
    },
    "state": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
      "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed"])},
      "postponed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postponed"])},
      "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
      "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])}
    },
    "time": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
      "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
    },
    "calendar": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
    },
    "customer": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "unfortunately_list_is_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately the list is empty"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading customers"])},
      "load_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load more..."])},
      "showing_C_of_T": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Showing ", _interpolate(_named("count")), " of ", _interpolate(_named("total")), " customers"])},
      "showing_all_C": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Showing all customers (", _interpolate(_named("count")), ")"])},
      "errors": {
        "required": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required."])},
          "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname is required."])},
          "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone is required."])},
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is required."])},
          "phone_or_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone or email is required."])}
        },
        "min": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name must be at least 2 characters."])},
          "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname must be at least 2 characters."])},
          "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone must be at least 10 digits."])}
        },
        "email_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Email must contain ", _interpolate(_named("sign")), " and must not contain spaces."])},
        "phone_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone must start with + and be at least 10 characters."])}
      }
    },
    "errors": {
      "required": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title is required."])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date is required."])},
        "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start and end is required."])},
        "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer is required."])}
      },
      "min": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title must be at least 2 characters."])}
      }
    }
  },
  "history": {
    "unfortunately_list_is_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately the list is empty"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading banquets"])},
    "load_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load more..."])},
    "showing_C_of_T": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Showing ", _interpolate(_named("count")), " of ", _interpolate(_named("total")), " banquets"])},
    "showing_all_C": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Showing all banquets (", _interpolate(_named("count")), ")"])},
    "selection": {
      "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])}
    },
    "filters": {
      "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
      "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Until"])}
    }
  },
  "restaurant": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])},
    "cancel_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel changes"])},
    "errors": {
      "required": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title is required."])},
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country is required."])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City is required."])},
        "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street is required."])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number is required."])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is required."])}
      },
      "min": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title must be at least 2 characters."])},
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country must be at least 2 characters."])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City must be at least 2 characters."])},
        "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street must be at least 2 characters."])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number must be at least 2 digits."])}
      },
      "email_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Email must contain ", _interpolate(_named("sign")), " and must not contain spaces."])},
      "phone_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone must start with + and be at least 10 characters."])}
    }
  }
}